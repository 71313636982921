<template>
  <div>
    <!-- 操作按钮 -->
    <div class="page-toolbar"
         v-if="configs.toolBarConfig"
         ref="tableForm">
      <vxe-toolbar>
        <template v-slot:buttons>
          <el-button v-for="(item,index) in configs.toolBarConfig"
                     :key="index"
                     :size="item.size || 'small'"
                     :icon="item.icon"
                     @click="buttonClick({val:item})"
                     :type="item.buttonType">{{item.name}}</el-button>
        </template>
      </vxe-toolbar>
    </div>
    <vxe-table v-bind="configs.tableConfig"
               show-overflow
                show-header-overflow
               v-model="value.tableData"
               @checkbox-change="checkboxChange"
               @checkbox-all="checkboxAll"
               @radio-change="radioChange"
               :checkbox-config="{checkRowKeys: selection}"
               :radio-config="{checkRowKey: selection[0]}"
               :edit-config="{trigger: configs.tableConfig.trigger, mode: 'cell',showIcon:false}"
               @edit-actived="editActivedEvent"
               :row-id="idKey || 'id'"
                size="mini"
                ref="xTable"
               :data="value.tableData"
               :height="isCalculateHeight?tableHeight:''">
      <vxe-table-column v-for="(item,index) in configs.columns"
                        min-width="120"
                        :key="index"
                        v-bind="item">
        <template v-if="!item.type"
                  v-slot:edit="{row,rowIndex}">
          <div class="setting-btn">
            <component :is="controls[item.editRender.name]"
                       size="small"
                       :readonly="item.editRender.props&&item.editRender.props.readonly"
                       :disabled="(item.editRender
                        && item.editRender.props
                        && item.editRender.props.disabled) || false"
                       @input="(val)=>onInput({val,item,rowIndex,row})"
                       @change="(val)=>onChange({val,item,rowIndex,row})"
                       v-model="row[item.field]"
                       v-bind="{...item.editRender.props}">
              <div v-if="item.editRender.options">
                <el-option v-for="child in item.editRender.options"
                           :key="child.value"
                           :label="child.label"
                           :value="child.value">
                </el-option>
              </div>
            </component>
          </div>
        </template>
        <template v-slot:default="{row,rowIndex}">
          <div v-if="item.editRender&&item.editRender.name === 'elSwitch'">
            <component :is="controls[item.editRender.name]"
                       size="small"
                       :readonly="item.editRender.props&&item.editRender.props.readonly"
                       :disabled="(item.editRender
                        && item.editRender.props
                        && item.editRender.props.disabled) || false"
                       :active-value ="item.editRender.props&&item.editRender.props.activeValue"
                       :inactive-value ="item.editRender.props&&item.editRender.props.inactiveValue"
                       @input="(val)=>onInput({val,item,rowIndex,row})"
                       @change="(val)=>onChange({val,item,rowIndex,row})"
                       v-model="row[item.field]"
                       v-bind="{...item.editRender.props}">
              <div v-if="item.editRender.options">
                <el-option v-for="child in item.editRender.options"
                           :key="child.value"
                           :label="child.label"
                           :value="child.value">
                </el-option>
              </div>
            </component>
          </div>
          <div class="setting-btn" v-if="!item.type && (!item.editRender||item.editRender.name !== 'elSwitch')">
            {{getOptionsLabel(row,item.field,item.editRender.options)}}
          </div>

          <div class="setting-btn" v-if="item.type === 'buttom'">
            <div class="setting-btn"
                 v-if="item.buttons.length === 1">
              <el-button type="text"
                         @click="(val) => clickCell({
                           val:item.buttons[0],row,rowIndex})">{{item.buttons[0].name}}
              </el-button>
            </div>
            <div class="setting-btn"
                 v-else>
              <el-dropdown @command="(val) => clickCell({val,row,rowIndex})">
                <span class="el-dropdown-link">
                  <span class="ellipsis">···</span>
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(btn,k) in item.buttons"
                                    :key="k"
                                    :command='btn'>{{btn.name}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <!-- 弹框 -->
    <Modal :modalConfig="modalConfig">
      <component
            :is="formName"
            @onClose="closeModal"
            @onGetSelect="onGetSelect"
            :formConfig="formConfig" />
    </Modal>
  </div>
</template>

<script>
import {
  Input, Select, InputNumber, Buttom, Switch, w,
} from 'element-ui';
import Modal from '../modal';
import Form from '../form';

export default {
  components: {
    Input,
    Select,
    InputNumber,
    Buttom,
    Modal,
    Form,
  },
  props: {
    value: Object,
    selectionList: Array,
    idKey: String,
  },
  data() {
    return {
      configs: {
        // 配置信息
        columns: [], // 列信息
        tableConfig: null, // 表信息
      },
      selection: [],
      controls: {
        elInput: Input,
        elSelect: Select,
        elInputNumber: InputNumber,
        elButtom: Buttom,
        elSwitch: Switch,
      },
      modalConfig: {}, // 弹框信息
      formConfig: null, // 弹框数据
      formName: 'Form',
      isCalculateHeight: false,
      tableHeight: '',
    };
  },

  watch: {
    selectionList(n) {
      this.selection = n || [];
    },
    value(n) {
      this.selection.forEach((v) => {
        n.tableData.map((d) => {
          const rowData = d;
          if (d[this.idKey] === v) {
            rowData.checked = true;
          }
          return rowData;
        });
      });
    },
  },
  methods: {
    // 获取弹框中的选中项
    onGetSelect(val) {
      val.forEach((v) => {
        let isReset = false;
        this.value.tableData.forEach((b) => {
          if (b.field === v.field) {
            isReset = true;
          }
        });
        if (!isReset) {
          console.log(v);
          this.value.tableData.push(v);
        }
      });
      console.log(val);
      // this.value.tableData = val;
    },
    // 点击表格触发编辑模式
    editActivedEvent({ rowIndex, row }) {
      return { rowIndex, row };
    },
    beforeGetList() {
      return true;
    },
    // 操作按钮前置事件
    beforeButtonClick() {
      return true;
    },
    // 操作按钮事件
    buttonClick({ val }) {
      if (!this.beforeButtonClick({ val })) return false;
      if (val.code === 'delete') {
        this.deleteClick({ val });
      } else {
        this.modalClick({ val });
      }
      return { ...val };
    },
    // 行操作事件
    clickCell({ val, row, rowIndex }) {
      if (val.code === 'delete') {
        this.deleteClick({ val, row, rowIndex });
      } else {
        this.modalClick({ val, row });
      }
      return { val, row };
    },
    // 弹框操作前置
    beforeModalClick() {
      return true;
    },
    // 弹框操作
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return false;
      this.openModal();
      return { val, row };
    },
    // 删除事件前置
    beforeDeleteClick() {
      return true;
    },
    // 删除事件
    deleteClick({ val, row, rowIndex }) {
      if (!this.beforeDeleteClick({ val, row })) return false;
      const data = {
        params: { ids: [] },
        url: '',
        title: '此操作将永久删除该文件, 是否继续?',
        msg: '删除',
      };
      if (row) {
        data.params.ids = [row.id];
      } else {
        this.selection.forEach((v) => {
          data.params.ids.push(v.id);
        });
      }
      this.messageBox(data, row, rowIndex);
      return { val, row };
    },
    // 确认弹框
    messageBox(data) {
      console.log(data);
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 打开抽屉
    openDrawer() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Drawer' },
      };
    },
    // 全屏打开
    openFull() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Full' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
    // 单选
    radioChange({ row }) {
      if (this.beforeRadioChange({ row })) {
        // 获取选择依据
        const { idKey } = this.configs.tableConfig;
        // 继续
        const rowData = row;
        rowData.checked = !rowData.checked;
        const list = [rowData[idKey]];
        this.selection = list;
      }
    },
    // 单选前置
    beforeRadioChange() {
      return true;
    },
    // 多选
    checkboxChange({ row, checked }) {
      // 获取选择依据
      const { idKey } = this.configs.tableConfig;
      // 判断是否选中
      if (!checked) {
        const list = [];
        const rowData = row;
        rowData.checked = false;
        // 重新赋值
        this.selection.map((v) => {
          if (v[idKey] !== row[idKey]) {
            list.push(v[idKey]);
          }
          return v;
        });
        this.selection = list;
      } else {
        const rowData = row;
        rowData.checked = true;
        this.selection.push(row[idKey]);
      }
    },
    // 全选
    checkboxAll(val) {
      this.value.tableData.forEach((row) => {
        const rowData = row;
        rowData.checked = false;
      });
      val.selection.forEach((row) => {
        const rowData = row;
        rowData.checked = true;
      });
      this.selection = val.selection;
    },
    // 下拉框显示label解析函数
    getOptionsLabel(row, field, options) {
      let showValue = row[field];
      if (options) {
        showValue = options.filter((v) => v.value === row[field]);
        showValue = showValue[0] ? showValue[0].label : '';
      }
      return showValue;
    },
  },
};
</script>
